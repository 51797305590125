import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import image from '../img/decophoto-web.png'; 
import backgroundImage from '../img/fond-middle-web.png'; 
import imageL from '../img/360nocal.png'; 
import imageR from '../img/deco-bas de page.png'; 

const Accueil = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const testimonials = [
    { id: 1, name: "John Doe", city: "Paris", function: "Engineer", text: "<< Le blablabla du client gérable et modifiable en admin, Le blablabla du client gérable et modifiable en admin, le blablabla du client gérable et modifiable en admin, L blablabla >>" },
    { id: 2, name: "Jane Smith", city: "New York", function: "Architect", text: "<< Le blablabla du client gérable et modifiable en admin, Le blablabla du client gérable et modifiable en admin, le blablabla du client gérable et modifiable en admin, L blablabla >>" },
    // Add more testimonials as needed
  ];

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? testimonials.length - 1 : currentSlide - 1);
  };

  const nextSlide = () => {
    setCurrentSlide(currentSlide === testimonials.length - 1 ? 0 : currentSlide + 1);
  };

  return (
    <div>
      <Header />
      <section className="bg-white w-full">
        <div className="   px-4 sm:px-6 lg:px-8 py-12 flex flex-col md:flex-row items-center ">
          <div className=" 2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 w-full text-left md:pr-12 2xl:ml-20">
            <p className="text-xl sm:text-xl  md:text-2xl lg:text-3xl 2xl:w-[100%] 2xl:text-[30px] 2xl:leading-[3rem] font-bold text-green-800 mb-10 md:mb-20 lg:ml-15">
              Etudes Photovoltaïques. Accompagnement aux démarches administratives et Dossiers techniques, Suivi de chantier -MOE - AMO
            </p>
           <div className=' ml-12 md:ml-12 lg:ml12  '> 
           <Link to="/creation-demande">
              <button
                type="submit"
                className="w-[90%] sm:w-[100%] md:w-[100%] lg:w-[100%]  py-3 sm:py-4 md:py-6 px-3 2xl:py-6 2xl:px-3 2xl:w-[80%] xl:w-[80%] bg-[#006462] text-[#ffdf76] font-bolder text-lg sm:text-xl md:text-2xl lg:text-4xl rounded-3xl"
                style={{ borderRadius: '50px' }}
              > NOUVELLE DEMANDE
               </button>
            </Link>
           </div>
            <div className="text-center flex 2xl:ml-24 lg:ml-16 md:ml-14 mt-4 text-sm sm:text-base md:text-sm ">
              <label className="block  text-[#aeb1b5] font-bold  items-center">
                Vous avez deja un compte ?{' '}
                <a href="/installer-form-login" className="text-[#276e6f] font-semibold underline ml-1">
                  Cliquez ICI
                </a>
              </label>
            </div>
          </div>
          <div className="2xl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-1/2 w-full mt-8 md:mt-0 flex justify-center 2xl:justify-center">
            <img src={image} alt="Enterprise" className="max-w-full 2xl:h-[490px] xl:h-auto lg:h-[500px] md:h-auto sm:h-auto" />
          </div>
        </div>
      </section>
      
      <section className="bg-cover bg-center flex justify-center py-12 2xl:block xl:block lg:block md:block sm:hidden hidden " style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="flex flex-col md:flex-row justify-between gap-8 md:gap-36 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:w-1/2">
            <img src={imageL} alt="Left Image" className="max-w-full h-auto mb-8 sm:mb-0" />
          </div>
          <div className="flex flex-col items-center justify-center md:w-1/2">
            <img src={imageR} alt="Right Image" className="max-w-full h-auto" />
            <div className="text-white mt-4 md:mt-0 p-4">
              <p className="text-base sm:text-lg md:text-xl max-w-md text-center md:text-left">
                La phrase de description de lampenergie, présentation courte des services sur 4 lignes maximum
              </p>
            </div>
          </div>
        </div>
      </section>
      
      <section className="bg-white py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col items-center">
          <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-[#338988] mb-6 underline">clients satisfaits</p>
          <div className="flex justify-content ">
            <button className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-[#006462] mr-4 sm:mr-8 focus:outline-none" onClick={prevSlide}>&lt;</button>
            <div className="flex-1 bg-white rounded-lg p-4 sm:p-6">
              <p className="text-base sm:text-lg md:text-xl">{testimonials[currentSlide].text}</p>
              <div className="text-center mt-4">
                <p className="font-bold text-base sm:text-lg md:text-xl text-[#0d0d0d]">{testimonials[currentSlide].name}, {testimonials[currentSlide].function}, {testimonials[currentSlide].city}</p>
              </div>
            </div>
            <button className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-[#006462] ml-4 sm:ml-8 focus:outline-none " onClick={nextSlide}>&gt;</button>
          </div>
        </div>
      </section>
      <div  className='mt-16'>      <Footer />
      </div>
    </div>
  );
};

export default Accueil;
