import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import Modal from '../components/modal';
import icon from '../img/icone-debuttexte.png';
import icon1 from '../img/icone-texte-jaune.png';
import { useNavigate } from 'react-router-dom';
import { updateFormData } from '../demandeSlice';


const Confirmation = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.demandes.formData); // Get form data from Redux store
  const navigate = useNavigate();
  const [signupData, setSignupData] = useState({
    entreprise: '',
    username: '',
    Adresse: '',
    Complementd_adresse: '',
    CP: '',
    Ville: '',
    email: '',
    tele: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let processedValue = value;
  
    if (type === 'checkbox') {
      processedValue = checked ? 1 : 0; 
    }
  
    if (modalContent === 'signup') {
      setSignupData((prevSignupData) => ({
        ...prevSignupData,
        [name]: processedValue, 
      }));
    } else {

      if (name === 'installation') {
        dispatch(updateFormData({
          [name]: value,
          installation_photovoltaique: value === 'oui' ? 1 : 0, 
        }));
      } else {
        dispatch(updateFormData({
          [name]: processedValue,
        }));
      }
    }
  };
  

  

  // Retrieve files passed from previous forms via Redux or location.state
  const {
    kbisEntrepriseFile = [],
    mandatSpecialFile = [],
    documentProprieteFile = [],
    copieFactureFile = [],
    proprieteFile = [],
    dessinsSchemaPhotoFile = [],
    photosAutresDocsFile = [] , 
    cinFile =  null 
  } = location.state || {};

  // Modal state for showing success or error messages
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

   // Print functionality without header and footer
   const handlePrint = () => {
    const printContent = document.getElementById('printable-section').innerHTML;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent;
    window.print();
    document.body.innerHTML = originalContent;
  };

  // Function to submit the request
  // const submitDemande = async () => {
  //   const token = localStorage.getItem('token');
    
  //   if (!token) {
  //     console.error('No token found. Please log in first.');
  //     setModalContent('signup');
  //     setShowModal(true);
  //     return;
  //   }
    
  //   const formDataToSend = new FormData();

  //   // Append multiple files for each input that allows multiple selections
  //   const appendFiles = (files, fieldName) => {
  //     if (Array.isArray(files) && files.length > 0) {
  //       files.forEach((file) => {
  //         if (file instanceof File) {
  //           formDataToSend.append(`${fieldName}[]`, file);
  //         }
  //       });
  //     }
  //   };

  //   // Append files from both Demande1 and Demande2
  //   appendFiles(kbisEntrepriseFile, 'kbis_entreprise');
  //   if (Array.isArray(mandatSpecialFile) && mandatSpecialFile.length > 0) {
  //     mandatSpecialFile.forEach(file => {
  //       formDataToSend.append('mandat_special[]', file);
  //     });
  //   }
  
  //   if (Array.isArray(kbisEntrepriseFile) && kbisEntrepriseFile.length > 0) {
  //     kbisEntrepriseFile.forEach(file => {
  //       formDataToSend.append('kbis_entreprise[]', file);
  //     });
  //   }

  //   if (copieFactureFile instanceof File) {
  //     formDataToSend.append('copie_facture[]', copieFactureFile); // Ensuring it's a File object
  //   }
  
  //   if (proprieteFile instanceof File) {
  //     formDataToSend.append('propriete[]', proprieteFile); // Ensuring it's a File object
  //   }
  //   if (cinFile instanceof File) {
  //     formDataToSend.append('cin', cinFile); // Ensuring it's a File object
  //   }


  //   if (dessinsSchemaPhotoFile instanceof File) {
  //     formDataToSend.append('dessins_schema_photo[]', dessinsSchemaPhotoFile); // Ensuring it's a File object
  //   }

  //   if (photosAutresDocsFile instanceof File) {
  //     formDataToSend.append('photos_autres_docs[]', photosAutresDocsFile); // Ensuring it's a File object
  //   }


  //   if (Array.isArray(documentProprieteFile) && documentProprieteFile.length > 0) {
  //     documentProprieteFile.forEach(file => {
  //       formDataToSend.append('document_propriete[]', file);
  //     });
  //   }

  //   appendFiles(copieFactureFile, 'copie_facture');
  //   appendFiles(proprieteFile, 'propriete');
  //   appendFiles(dessinsSchemaPhotoFile, 'dessins_schema_photo');
  //   appendFiles(photosAutresDocsFile, 'photos_autres_docs');

  //   // Debug: Log formData before sending to backend
  //   for (let pair of formDataToSend.entries()) {
  //       console.log(pair[0]+ ', ' + pair[1]);
  //   }

  //   // Append form fields to formData
  //   const prestationsArray = Array.isArray(formData.prestations_souhaitees)
  //     ? formData.prestations_souhaitees
  //     : formData.prestations_souhaitees.split(',').map(item => item.trim());
    
  //   prestationsArray.forEach((item) => formDataToSend.append('prestations_souhaitees[]', item));

  //   // Append remaining form data
  //   const branchementCompteur = [];
  //   if (formData.monophasé) branchementCompteur.push('Monophasé');
  //   if (formData.triphasé) branchementCompteur.push('Triphasé');
    
  //   // Ensure that the value is valid
  //   if (branchementCompteur.length === 0) {
  //     console.error("Please select a valid branchement compteur.");
  //     return;
  //   }

  //   formDataToSend.append('branchement_compteur', branchementCompteur.join(','));
  //   formDataToSend.append('destination_batiment', formData.destination_batiment || '');
  //   formDataToSend.append('installateur_email', formData.installateur_email || '');
  //   formDataToSend.append('installateur_telephone', formData.installateur_telephone || '');
  //   formDataToSend.append('marques_modeles_panneaux_onduleurs', formData.marques_modeles_panneaux_onduleurs || '');
  //   formDataToSend.append('puissance_prevue', formData.puissance_prevue || '');
  //   formDataToSend.append('type_client', formData.type_client || '');
  //   formDataToSend.append('type_installation', formData.type_installation || '');
  //   formDataToSend.append('type_production', formData.type_production || '');
  //   formDataToSend.append('nom_projet', formData.nom_projet || '');
  //   formDataToSend.append('nom', formData.nom || '');
  //   formDataToSend.append('installation_photovoltaique', formData.installation_photovoltaique ? 1 : 0);
  //   formDataToSend.append('autoconsommation', formData.autoconsommation ? 1 : 0);

  //   // Send request to API
  //   try {
  //     const response = await axios.post('http://localhost:8000/api/demande/add', formDataToSend, {
  //       // const response = await axios.post('https://www.lampenergie.fr/admin/public/api/demande/add', formDataToSend, {

  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     console.log("Response:", response.data);
  //     setModalContent('success');
  //     setShowModal(true);
  //   } catch (error) {
  //     console.error("Error submitting demande:", error.response ? error.response.data : error.message);
  //     setModalContent('error');
  //     setShowModal(true);
  //   }
  // };
  const submitDemande = async () => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      console.error('No token found. Please log in first.');
      setModalContent('signup');
      setShowModal(true);
      return;
    }
    
    const formDataToSend = new FormData();
  
    // Append multiple files for each input that allows multiple selections
    const appendFiles = (files, fieldName) => {
      if (Array.isArray(files) && files.length > 0) {
        files.forEach((file) => {
          if (file instanceof File) {
            formDataToSend.append(`${fieldName}[]`, file);
          }
        });
      }
    };
  
    // Append files from both Demande1 and Demande2
    appendFiles(kbisEntrepriseFile, 'kbis_entreprise');
    appendFiles(mandatSpecialFile, 'mandat_special');
    appendFiles(copieFactureFile, 'copie_facture');
    appendFiles(proprieteFile, 'propriete');
    appendFiles(dessinsSchemaPhotoFile, 'dessins_schema_photo');
    appendFiles(photosAutresDocsFile, 'photos_autres_docs');
    
    if (cinFile instanceof File) {
      formDataToSend.append('cin', cinFile); // Ensuring it's a File object
    }
  
    // Debug: Log formData before sending to backend
    for (let pair of formDataToSend.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
    }
  
    // Append other fields from formData
    const prestationsArray = Array.isArray(formData.prestations_souhaitees)
      ? formData.prestations_souhaitees
      : formData.prestations_souhaitees.split(',').map(item => item.trim());
    
    prestationsArray.forEach((item) => formDataToSend.append('prestations_souhaitees[]', item));
  
    // Append remaining form data
    const branchementCompteur = [];
    if (formData.monophasé) branchementCompteur.push('Monophasé');
    if (formData.triphasé) branchementCompteur.push('Triphasé');
    
    formDataToSend.append('branchement_compteur', branchementCompteur.join(','));
  
    // Add other fields from the form
    formDataToSend.append('destination_batiment', formData.destination_batiment || '');
    formDataToSend.append('installateur_email', formData.installateur_email || '');
    formDataToSend.append('installateur_telephone', formData.installateur_telephone || '');
    formDataToSend.append('marques_modeles_panneaux_onduleurs', formData.marques_modeles_panneaux_onduleurs || '');
    formDataToSend.append('puissance_prevue', formData.puissance_prevue || '');
    formDataToSend.append('type_client', formData.type_client || '');
    formDataToSend.append('type_installation', formData.type_installation || '');
    formDataToSend.append('type_production', formData.type_production || '');
    formDataToSend.append('nom_projet', formData.nom_projet || '');
    formDataToSend.append('nom', formData.nom || '');
    formDataToSend.append('prenom', formData.prenom || '');
    formDataToSend.append('date_de_naissance', formData.date_de_naissance || '');
    formDataToSend.append('lieu_de_naissance', formData.lieu_de_naissance || '');
    formDataToSend.append('pays', formData.pays || '');
    formDataToSend.append('telephone_fixe', formData.telephone_fixe || '');
    formDataToSend.append('telephone_mobile', formData.telephone_mobile || '');
    formDataToSend.append('email', formData.email || '');
    formDataToSend.append('cp', formData.cp || '');
    formDataToSend.append('ville', formData.ville || '');


    formDataToSend.append('adresse', formData.adresse || '');
    formDataToSend.append('Complementd_adresse', formData.Complementd_adresse || '');
    formDataToSend.append('ref_cadastrale_parcelle', formData.ref_cadastrale_parcelle || '');
    formDataToSend.append('ref_cadastrale_section', formData.ref_cadastrale_section || '');
    formDataToSend.append('coordonnees_gps', formData.coordonnees_gps || '');
    formDataToSend.append('surface_totale_parcelle', formData.surface_totale_parcelle || '');
    formDataToSend.append('puissance_ancienne_installation', formData.puissance_ancienne_installation || '');
    formDataToSend.append('modele_ancien_onduleur', formData.modele_ancien_onduleur || '');
    formDataToSend.append('marque_ancien_onduleur', formData.marque_ancien_onduleur || '');
    formDataToSend.append('numero_crae', formData.numero_crae || '');
    formDataToSend.append('date_mes', formData.date_mes || '');


    formDataToSend.append('cp_production', formData.cp_production || '');
    formDataToSend.append('ville_production', formData.ville_production || '');
    formDataToSend.append('adresse_production', formData.adresse_production || '');
    formDataToSend.append('complement_adresse_production', formData.complement_adresse_production || '');
  
    formDataToSend.append('installation_photovoltaique', formData.installation_photovoltaique ? 1 : 0);
    formDataToSend.append('autoconsommation', formData.autoconsommation ? 1 : 0);
    formDataToSend.append('nom_beneficiaire', formData.nom_beneficiaire || '');
    formDataToSend.append('dossier_traite', formData.dossier_traite || '0%');
    formDataToSend.append('demande_statut', formData.demande_statut || 'non traiter');
  formDataToSend.append('dp_date_depot_statut', formData.dp_date_depot_statut || 'non traite');
  formDataToSend.append('dp_delai_statut', formData.dp_delai_statut || 'non traite');
  formDataToSend.append('dp_piece_statut', formData.dp_piece_statut || 'non traite');
  formDataToSend.append('enedis_date_mes_statut', formData.enedis_date_mes_statut || 'non traite');
  formDataToSend.append('enedis_pcr_statut', formData.enedis_pcr_statut || 'non traite');
  formDataToSend.append('enedis_piece_statut', formData.enedis_piece_statut || 'non traite');
  formDataToSend.append('consuel_date_completude_statut', formData.consuel_date_completude_statut || 'non traite');
  formDataToSend.append('consuel_date_visa_statut', formData.consuel_date_visa_statut || 'non traite');
  formDataToSend.append('consuel_piece_statut', formData.consuel_piece_statut || 'non traite');
  formDataToSend.append('doe_rapport_statut', formData.doe_rapport_statut || 'non traite');
  formDataToSend.append('doe_envoye_statut', formData.doe_envoye_statut || 'non traite');
  formDataToSend.append('doe_piece_statut', formData.doe_piece_statut || 'non traite');
  formDataToSend.append('edf_date_statut', formData.edf_date_statut || 'non traite');
  formDataToSend.append('edf_code_statut', formData.edf_code_statut || 'non traite');
  formDataToSend.append('edf_piece_statut', formData.edf_piece_statut || 'non traite');
    try {
       const response = await axios.post('https://www.lampenergie.fr/admin/public/api/demande/add', formDataToSend, {
  headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Response:", response.data);
      setModalContent('success');
      setShowModal(true);
    } catch (error) {
      console.error("Error submitting demande:", error.response ? error.response.data : error.message);
      setModalContent('error');
      setShowModal(true);
    }
  };
  


  const handleSignupSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // const response = await fetch('http://localhost:8000/api/register', {
        const response = await fetch('https://www.lampenergie.fr/admin/public/api/register', {
  
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          entreprise: signupData.entreprise,
          username: signupData.username,
          Adresse: signupData.Adresse,
          Complementd_adresse: signupData.Complementd_adresse,
          CP: signupData.CP,
          Ville: signupData.Ville,
          email: signupData.email,
          tele: signupData.tele,
          password: signupData.password,
          role: 'installateur',
        }),
      });
  
      if (!response.ok) {
        const contentType = response.headers.get('content-type');
        let errorData;
  
        if (contentType && contentType.includes('application/json')) {
          errorData = await response.json();
        } else {
          errorData = { message: 'Unexpected response format from server' };
        }
  
        console.error('Signup errors:', errorData.errors || errorData.message || 'Unknown error occurred');
        setModalContent('error');
      } else {
        const data = await response.json();
        localStorage.setItem('token', data.token); // Store the token
        await submitDemande(data.token); // Submit the demand form with the new token
        setModalContent('success'); // Show success modal
      }
    } catch (error) {
      console.error('Error:', error.message || error);
      setModalContent('error');
    }
  };
  // const handleModalClose = () => {
  //   setShowModal(false);

  //   // Réinitialiser les champs du formulaire
  //   dispatch(updateFormData({
  //       ref_cadastrale_section: '',
  //       ref_cadastrale_parcelle: '',
  //       surface_totale_parcelle: '',
  //       coordonnees_gps: '',
  //       monophasé: false,
  //       triphasé: false,
  //       installation: 'non',
  //       nom_projet:'',
  //       date_mes: '',
  //       numero_crae: '',
  //       marque_ancien_onduleur: '',
  //       modele_ancien_onduleur: '',
  //       puissance_ancienne_installation: '',
  //       nom: '',
  //       prenom: '',
  //       date_de_naissance: '',
  //       lieu_de_naissance: '',
  //       pays: '',
  //       adresse: '',
  //       complement_adresse: '',
  //       cp: '',
  //       ville: '',
  //       telephone_fixe: '',
  //       telephone_mobile: '',
  //       email: '',
  //       autoconsommation: false,
  //       nom_beneficiaire: '',
  //   }));

  //   // Réinitialiser les fichiers
  //   setFiles({
  //       copieFacture: null,
  //       propriete: null,
  //       dessinsSchemaPhoto: [],
  //       photosAutresDocs: [],
  //       mandat_special: null,
  //       kbis_entreprise: null,
  //       document_propriete: null,
  //       cin: null,
  //   });
  // };

  const renderModalContent = () => {
    switch (modalContent) {
      case 'success':
        return (
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Enregistrement réussi</h2>
            <p>Votre demande a été enregistrée avec succès.</p>
            <button
              className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
              
            >
              Fermer
            </button>
          </div>
        );
      case 'error':
        return (
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Erreur</h2>
            <p>Une erreur est survenue lors de l'enregistrement. Veuillez vérifier les champs et réessayer.</p>
            <button
              className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
              onClick={() => setShowModal(false)}
            >
              Fermer
            </button>
          </div>
        );
      case 'signup':
        return (
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Connexion requise</h2>
            <p>Vous devez être connecté pour soumettre ce formulaire. Veuillez vous inscrire ou vous connecter.</p>
            <form onSubmit={handleSignupSubmit}>
              <input
                type="text"
                name="entreprise"
                placeholder="Entreprise"
                value={signupData.entreprise}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="username"
                placeholder="Nom du dirigeant"
                value={signupData.username}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="Adresse"
                placeholder="Adresse"
                value={signupData.Adresse}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="Complementd_adresse"
                placeholder="Complément d’adresse"
                value={signupData.Complementd_adresse}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="number"
                name="CP"
                placeholder="CP"
                value={signupData.CP}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="Ville"
                placeholder="Ville"
                value={signupData.Ville}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={signupData.email}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="text"
                name="tele"
                placeholder="Téléphone"
                value={signupData.tele}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <input
                type="password"
                name="password"
                placeholder="Mot de passe"
                value={signupData.password}
                onChange={handleChange}
                className="block w-full px-2 py-2 mb-4 border border-gray-300 rounded"
              />
              <button
                type="submit"
                className="mt-4 py-2 px-4 bg-[#006462] text-white rounded w-full"
              >
                S'inscrire / Se connecter
              </button>
            </form>
          </div>
        );
      default:
        return null;
    }
  };
  // Close the modal
  const closeModal = () => {
    setShowModal(false);
  };

 return (
    <div className="w-full h-full overflow-x-hidden">
      {/* Header visible only on screen, hidden for printing */}
      <header className="print:hidden">
        <Header />
      </header>

      {/* Printable section */}
      <div id="printable-section" className="flex flex-col bg-white py-6 px-4 sm:px-6 md:px-12 lg:px-24 xl:px-24 2xl:px-24">
        <p className="self-start text-lg font-medium text-[#616565] md:text-2xl lg:text-3xl">
          <img src={icon} alt="icon" className="inline-block w-6 h-6 align-middle mx-2" /> Confirmation des informations de la demande d'étude :
        </p>

        {/* Client type */}
        <div className="mt-4">
          <p><strong>Type de client :</strong> {formData.type_client}</p>
        </div>

        {/* Conditional display based on client type */}
        {formData.type_client === 'Particulier' ? (
          <div>
            <div className="mt-4">
              <p><strong>E-mail installateur :</strong> {formData.installateur_email}</p>
              <p><strong>Téléphone installateur :</strong> {formData.installateur_telephone}</p>
            </div>
            <div className="mt-4">
              <p><strong>Prestations souhaitées :</strong> {formData.prestations_souhaitees?.join(', ')}</p>
              {formData.autrePrecision && (
                <p><strong>Précision autre prestation :</strong> {formData.autrePrecision}</p>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="mt-4">
             <p><strong>E-mail installateur :</strong> {formData.installateur_email}</p>
              <p><strong>Téléphone installateur :</strong> {formData.installateur_telephone}</p>
              <p><strong>Nom de l'entreprise :</strong> {formData.nom_entreprise}</p>
              <p><strong>Numéro SIRET :</strong> {formData.numero_siret}</p>
              <p><strong>Type d'activité :</strong> {formData.type_activite}</p>
              <p><strong>E-mail de l'entreprise :</strong> {formData.entreprise_email}</p>
              <p><strong>Téléphone de l'entreprise :</strong> {formData.entreprise_telephone}</p>
            </div>
            <div className="mt-4">
              <p><strong>Prestations souhaitées :</strong> {formData.prestations_souhaitees?.join(', ')}</p>
              {formData.autrePrecision && (
                <p><strong>Précision autre prestation :</strong> {formData.autrePrecision}</p>
              )}
            </div>
          </div>
        )}

        {/* Fields common to all clients */}
        <div className="mt-4">
          <p><strong>Type d'installation :</strong> {formData.type_installation}</p>
          <p><strong>Puissance prévue :</strong> {formData.puissance_prevue} kWc</p>
          <p><strong>Marques et modèles panneaux et onduleurs :</strong> {formData.marques_modeles_panneaux_onduleurs}</p>
          <p><strong>Type de production :</strong> {formData.type_production}</p>
        </div>

      

        <div className="mt-4">
          <p><strong>Destination du bâtiment :</strong> {formData.destination_batiment}</p>
          {formData.precisions_destination && (
            <p><strong>Précision destination :</strong> {formData.precisions_destination}</p>
          )}
        </div>

        <div className="mt-4">
          <p><strong>Adresse :</strong> {formData.adresse_production}</p>
          <p><strong>Complément d'adresse :</strong> {formData.complement_adresse_production}</p>
          <p><strong>Ville :</strong> {formData.ville_production}</p>
          <p><strong>Code postal :</strong> {formData.cp_production}</p>
        </div>

        <div className="mt-4">
          <p><strong>Devis (matériel/fournisseurs) :</strong> {formData.devis}</p>
        </div>

        {/* Additional fields for second part */}
        <div className="mt-4">
          <p className="font-medium text-lg text-[#616565]">Références cadastrales du terrain :</p>
          <p><strong>N° de section :</strong> {formData.ref_cadastrale_section}</p>
          <p><strong>N° de parcelle :</strong> {formData.ref_cadastrale_parcelle}</p>
          <p><strong>Surface totale :</strong> {formData.surface_totale_parcelle} m²</p>
          <p><strong>Coordonnées GPS :</strong> {formData.coordonnees_gps}</p>
        </div>

        <div className="mt-4">
          <p className="font-medium text-xl text-[#616565]">Informations sur le branchement :</p>
          <p><strong>Branchement compteur :</strong> {formData.monophase ? 'Monophasé' : 'Triphasé'}</p>
        </div>

        {formData.installation === 'oui' && (
          <div className="mt-4">
            <p className="font-medium text-xl text-[#616565]">Détails de l'ancienne installation :</p>
            <p><strong>Nom projet :</strong> {formData.nom_projet}</p>
            <p><strong>Date de MES :</strong> {formData.date_mes}</p>
            <p><strong>N° de CRAE :</strong> {formData.numero_crae}</p>
            <p><strong>Marque de l'ancien onduleur :</strong> {formData.marque_ancien_onduleur}</p>
            <p><strong>Modèle de l'ancien onduleur :</strong> {formData.modele_ancien_onduleur}</p>
            <p><strong>Puissance ancienne installation :</strong> {formData.puissance_ancienne_installation} kWc</p>
          </div>
        )}

        {formData.installation === 'non' && (
          <div className="mt-4">
            <p className="font-medium text-xl text-[#616565]">Informations du client/producteur :</p>
            <p><strong>Nom :</strong> {formData.nom}</p>
            <p><strong>Prénom :</strong> {formData.prenom}</p>
            <p><strong>Date de naissance :</strong> {formData.date_de_naissance}</p>
            <p><strong>Lieu de naissance :</strong> {formData.lieu_de_naissance}</p>
            <p><strong>Pays :</strong> {formData.pays}</p>
            <p><strong>Adresse :</strong> {formData.adresse}</p>
            <p><strong>Complément d'adresse :</strong> {formData.Complementd_adresse}</p>
            <p><strong>CP :</strong> {formData.cp}</p>
            <p><strong>Ville :</strong> {formData.ville}</p>
            <p><strong>Téléphone fixe :</strong> {formData.telephone_fixe}</p>
            <p><strong> Mobile :</strong> {formData.telephone_mobile}</p>
            <p><strong>Mail :</strong> {formData.email}</p>







          </div>
        )}
<div className="mt-4">
  {/* Other fields */}
  
  {formData.autoconsommation === 'oui' && (
    <div className="mt-4">
      <p><strong>Nom du bénéficiaire :</strong> {formData.nom_beneficiaire}</p>
    </div>
  )}

  {/* Other fields */}
</div>

         {/* Show installation_photovoltaique */}
         <div className="mt-4">
          <p><strong>Installation Photovoltaïque :</strong> {formData.installation_photovoltaique ? 'Oui' : 'Non'}</p>
        </div>

        {/* Show Autoconsommation only if installation_photovoltaique is "Non" */}
        {formData.installation_photovoltaique !== 'oui' && (
          <div className="mt-4">
            <p><strong>Autoconsommation :</strong> {formData.autoconsommation ? 'Oui' : 'Non'}</p>
          </div>
        )}
      </div>

      {/* Confirmation and print buttons */}
      <div className="flex flex-col sm:flex-row justify-between items-center mt-6 mb-10 sm:space-x-4 space-y-4 sm:space-y-0">
  <button
    type="button"
    onClick={() => navigate(-1)} 
    className="w-[20%] py-2 sm:py-2 md:py-3 lg:py-3 xl:py-3 2xl:py-4 px-4 bg-[#006462] text-[#ffdf76] font-normal text-lg sm:text-lg md:text-xl lg:text-xl xl:text-xl 2xl:text-2xl rounded-3xl 2xl:ml-20 xl:ml-20 lg:ml-20"
    style={{ borderRadius: '50px' }}
  >
    RETOUR
  </button>

  <div className="flex flex-col sm:flex-row sm:space-x-4 gap-2 sm:space-y-0">
    <button
      type="button"
      className="w-[200px] py-2 px-8 bg-[#006462] text-[#ffdf76] font-normal text-xl rounded-full flex"
      onClick={handlePrint}
    >
      Imprimer
    </button>

    <button
      type="button"
      className="w-[200px] py-2 px-8 bg-[#006462] text-[#ffdf76] font-normal text-xl rounded-full flex"
      onClick={submitDemande}
    >
      Enregistrer
    </button>
  </div>
</div>


      {/* Success or error modal */}
      {showModal && (
        <Modal onClose={closeModal}>
          <div className="bg-white p-6 rounded shadow-lg">
            {modalContent === 'success' ? (
              <>
                <h2 className="text-xl font-semibold mb-4">Succès</h2>
                <p>Votre demande a été soumise avec succès !</p>
              </>
            ) : (
              <>
                <h2 className="text-xl font-semibold mb-4">Erreur</h2>
                <p>Une erreur est survenue lors de la soumission de la demande.</p>
              </>
            )}
            <button
              className="mt-4 py-2 px-4 bg-[#006462] text-white rounded"
              onClick={closeModal}
            >
              Fermer
            </button>
          </div>
        </Modal>
      )}
{/* Other modal cases */}
{showModal && modalContent !== '' && (
    <Modal onClose={() => setShowModal(false)}>
        {renderModalContent()}
    </Modal>
)}

      {/* Footer hidden during printing */}
      <footer className="print:hidden">
        <Footer />
      </footer>
    </div>
  );
};

export default Confirmation;
