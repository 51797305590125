// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// import valide from '../img/ico-valid.png';
// import non from '../img/ico-non.png';
// import vorange from '../img/ico-Vorange.png';
// import iconSearch from '../img/ico-loupe.png';
// import icon from '../img/icone-debuttexte.png';
// import StyledButton from '../components/styleButton';
// import UploadModal from '../components/UploadModal'; // Importer le modal pour téléchargement

// const Dashboard = () => {
//   const [demandes, setDemandes] = useState([]);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedField, setSelectedField] = useState(null); // Pour savoir quel champ est en cours d'upload
//   const [selectedDemandeId, setSelectedDemandeId] = useState(null); // Pour stocker l'ID de la demande sélectionnée
//   const navigate = useNavigate();
//   const token = localStorage.getItem('token');

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetch('http://localhost:8000/api/user-demandes', {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         if (!response.ok) {
//           throw new Error('Failed to fetch data');
//         }
//         const data = await response.json();
//         setDemandes(data);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [token]);

//   // Ouvrir le modal pour le champ sélectionné et l'ID de la demande
//   const handleOpenModal = (field, demandeId) => {
//     setSelectedField(field); // Champ spécifique
//     setSelectedDemandeId(demandeId); // ID de la demande sélectionnée
//     setIsModalOpen(true); // Ouvrir le modal
//   };

//   const handleSaveFiles = async (files) => {
//     const formData = new FormData();
//     for (let i = 0; i < files.length; i++) {
//       formData.append('files[]', files[i]);
//     }
//     formData.append('field', selectedField);
//     formData.append('id', selectedDemandeId);

//     console.log('FormData envoyé:', [...formData.entries()]);

//     try {
//       const response = await fetch('http://localhost:8000/api/upload-files', {
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//         body: formData,
//       });

//       if (response.ok) {
//         console.log('Fichiers enregistrés avec succès.');
//       } else {
//         const errorData = await response.json();
//         console.error('Erreur lors de la sauvegarde des fichiers:', errorData);
//       }
//     } catch (error) {
//       console.error('Erreur:', error);
//     }
//   };



//   const renderStatuPIcon = (status) => {
//     switch (status) {
//       case 'traiter':
//         return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
//       case 'en cours':
//         return <img src={vorange} alt="En cours" className="w-6 h-6 inline-block" />;
//       case 'non traite':
//         return <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />;
//       default:
//         return null;
//     }
//   };


//   const renderStatusIcon = (status) => {
//     switch (status) {
//       case 'traiter':
//         return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
//       case 'en cours':
//         return <img src={vorange} alt="En cours" className="w-6 h-6 inline-block" />;
//       case 'non traite':
//         return <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />;
//       default:
//         return null;
//     }
//   };



//   // Fonction pour afficher l'icône en fonction de l'état des fichiers
//   const renderStatuIcon = (value, field, demandeId) => {
//     if (
//       value === null || 
//       value === "null" || 
//       value === "" || // Si c'est une chaîne vide
//       JSON.stringify(value) === "[]" || 
//       value === "[[]]" || // Si c'est un tableau vide sous forme de chaîne
//       (Array.isArray(value) && value.length === 0) // Si c'est un tableau vide
//     ) {
//       return (
//         <>
//           <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />
//           <button
//             className="text-red-500 font-bold underline text-[13px]"
//             onClick={() => handleOpenModal(field, demandeId)} // Passez l'ID de la demande ici
//           >
//             Télécharger
//           </button>
//         </>
//       );
//     } else {
//       return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
//     }
//   };
//   return (
//     <div>
//       <Header />
//       <div className="w-full h-full mx-auto px-4 md:px-4 lg:px-16 xl:px-20 2xl:px-20">
//         <div className="flex flex-col sm:flex-col md:justify-between lg:justify-between xl:justify-between md:flex-row lg:flex-row xl:flex-row 2xl:flex-row lg:items-center mb-4">
//           <div>
//             <ul className="mt-2 ml-4">
//               <li className="mb-1">
//                 <img src={icon} alt="icon" className="inline-block w-6 h-6 -ml-2" />
//                 <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-2" onClick={() => navigate('/mon-compte')}>
//                   Dossiers en cours
//                 </a>
//               </li>
//               {/* Autres éléments du menu */}
//             </ul>
//           </div>
//           <StyledButton type="button" onClick={() => navigate('/creation-demande')}>
//             NOUVELLE DEMANDE
//           </StyledButton>
//         </div>

//         <div className="mb-4">
//           <div className="flex items-center p-2 border rounded-lg 2xl:w-[34%] xl:w-[34%] lg:w-[34%] md:w-[34%]">
//             <input
//               type="text"
//               placeholder="RECHERCHE DE DOSSIERS"
//               className="flex-grow p-2 focus:outline-none"
//             />
//             <img src={iconSearch} alt="Search" className="w-6 h-6 ml-2" />
//           </div>
//         </div>

//         {demandes.map((demande, index) => (
//           <div key={index} className="w-[100%] mb-14">
//             <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
//               {/* Gestion des documents */}
//               <div className="bg-[#d9d8d8]">
//                 <div className="p-3 bg-[#c3dddc]">
//                   <h1 className="text-xl font-normal text-black flex justify-start">{demande.nom_projet || demande.nom}</h1>
//                 </div>
//                 <div className="bg-[#d9d8d8] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
//                   <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-8 p-2 text-[#1c7573] underline">Gestion des documents:</h3>

//                   {/* Fiche informations */}
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/2">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Fiche informations</p>
//                     </div>
//                     <div className="w-1/2">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatuIcon(demande.fiche_info, 'fiche_info',demande.id)}
//                       </div>
//                     </div>
//                   </div>

//                   {/* Kbis Entreprise */}
//                   {demande.type_client === 'professionnel' && (
//                     <div className="flex flex-row mb-2 w-[100%] gap-2">
//                       <div className="w-1/2">
//                         <p className="flex justify-end text-[13px]">Kbis Entreprise</p>
//                       </div>
//                       <div className="w-1/2">
//                         <div className="flex flex-row items-center gap-2">
//                           {renderStatuIcon(demande.kbis_entreprise, 'kbis_entreprise',demande.id)}
//                         </div>
//                       </div>
//                     </div>
//                   )}

//                   {/* Mandat */}
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/2">
//                       <p className="flex justify-end text-[13px]">Mandat</p>
//                     </div>
//                     <div className="w-1/2">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatuIcon(demande.mandat_special, 'mandat_special',demande.id)}
//                       </div>
//                     </div>
//                   </div>

//                   {/* Dessins, schéma, Photo */}
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/2">
//                       <p className="flex justify-end text-[13px]">Dessins, schéma, Photo</p>
//                     </div>
//                     <div className="w-1/2">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatuIcon(demande.dessins_schema_photo, 'dessins_schema_photo',demande.id)}
//                       </div>
//                     </div>
//                   </div>

//                   {/* Photos, autres docs */}
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/2">
//                       <p className="flex justify-end text-[13px]">Photos, autres docs</p>
//                     </div>
//                     <div className="w-1/2">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatuIcon(demande.photos_autres_docs, 'photos_autres_docs',demande.id)}
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//              {/* Voir les Informations */}
//              <div className="bg-[#eeebeb]">
//                 <div className="p-3 bg-[#1c7573]">
//                   <h1 className="text-xl font-normal text-center text-[#ffdf76]">Voir les Informations</h1>
//                 </div>
//                 <div className="bg-[#d9d8d8] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
//                   <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-3 p-2 text-[#1c7573]">
//                     DP Urbanisme<span className="text-black font-normal">- Avancement :</span>
//                   </h3>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/4">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date dépôt:</p>
//                     </div>
//                     <div className="">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.dp_date_depot_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_date_depot}</p>
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_date_depot_text}</p>
//                     </div>
//                   </div>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2 -ml-8">
//                     <div className="w-1/3">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Délai :</p>
//                     </div>
//                     <div className="w-[67%]">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.dp_delai_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_delai}</p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="flex gap-2 items-center justify-start pl-11">
//                     {renderStatuPIcon (demande.dp_piece_statut)}               
//                     <div className="ml-1 text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
//                     <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_piece}</p>
//                   </div>
//                 </div>

//                 <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
//                   <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-3 p-2 text-[#1c7573]">
//                     ENEDIS ou Régle<span className="text-black font-normal">- Avancement :</span>
//                   </h3>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/3">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date MES:</p>
//                     </div>
//                     <div className="w-[67%]">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.enedis_date_mes_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_mes}</p>
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_mes_text}</p>
//                     </div>
//                   </div>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/3">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date PCR:</p>
//                     </div>
//                     <div className="w-[67%]">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.enedis_pcr_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_pcr}</p>
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_pcr_text}</p>
//                     </div>
//                   </div>
//                   <div className="flex gap-2 items-center justify-start pl-11">
//                     {renderStatuPIcon(demande.enedis_piece_statut)}               
//                     <div className="ml-1 text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
//                     <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_piece}</p>
//                   </div>
//                 </div>
//               </div>

//               {/* Dossier traité */}
//               <div className="bg-[#eeebeb]">
//                 <div className="p-3 bg-[#c3dddc]">
//                   <h1 className="text-xl font-normal text-[#1c7573] flex justify-end pr-10">Dossier traité à : <span className="font-bold">{demande.dossier_traite}</span></h1>
//                 </div>
//                 <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
//                   <h3 className="text-lg lg:text-[1rem] font-bold mb-3 p-2 text-[#1c7573]">
//                     CONSUEL<span className="text-black font-normal">- Avancement :</span>
//                   </h3>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/3">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date complétude:</p>
//                     </div>
//                     <div className="">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.consuel_date_completude_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_completude}</p>
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_completude_text}</p>
//                     </div>
//                   </div>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/3">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date Visa:</p>
//                     </div>
//                     <div className="">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.consuel_date_visa_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_visa}</p>
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_visa_text}</p>
//                     </div>
//                   </div>
//                   <div className="flex gap-2 items-center justify-start pl-11">
//                     {renderStatuPIcon (demande.consuel_piece_statut)}               
//                     <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
//                     <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_piece}</p>
//                   </div>
//                 </div>


//                 <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
//                   <h3 className="text-lg lg:text-[1rem] font-bold mb-3 p-2 text-[#1c7573]">
//                   DOE (Option si PRO)<span className="text-black font-normal">- Avancement :</span>
//                   </h3>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/3">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Rapport:
//                       </p>
//                     </div>
//                     <div className="">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.doe_rapport_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_rapport}</p>
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_rapport_text}</p>
//                     </div>
//                   </div>
//                   <div className="flex flex-row mb-2 w-[100%] gap-2">
//                     <div className="w-1/3">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Envoyé/encours:</p>
//                     </div>
//                     <div className="">
//                       <div className="flex flex-row items-center gap-2">
//                         {renderStatusIcon(demande.doe_envoye_statut)}
//                         <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_envoye}</p>
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_envoi_text}</p>
//                     </div>
//                   </div>
//                   <div className="flex gap-2 items-center justify-start pl-11">
//                     {renderStatuPIcon (demande.doe_piece_statut)}               
//                     <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
//                     <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_piece}</p>
//                   </div>
//                 </div>

//               </div>
//             </div>

//             {/* EDF OA OPTION */}
//             <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
//               <h3 className="text-md font-bold mb-3 p-2 text-[#1c7573] lg:text-[1rem]">EDF OA OPTION<span className="text-black font-normal">- Avancement :</span></h3>
//               <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-2">
//                 <div className="flex flex-row justify-center sm:justify-center md:gap-12 lg:gap-12 xl:gap-2 2xl:gap-2">
//                   <div className="flex justify-center items-center">
//                     <p className="flex justify-end text-[13px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[14px] 2xl:text-[15px]">Date création du portail:</p>
//                     <div className="flex flex-row items-center gap-2">
//                       {renderStatusIcon(demande.edf_date_statut)}
//                       <p className="flex justify-end text-[12px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">{demande.edf_date}:</p>
//                     </div>
//                   </div>
//                   <div className="hidden sm:hidden md:hidden lg:block xl:block 2xl:block"></div>
//                   <div className="">
//                     <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_date_creation_text}</p>
//                   </div>
//                 </div>

//                 <div className="flex flex-row justify-center sm:justify-center md:gap-12 lg:gap-12 xl:gap-14 2xl:gap-2">
//                   <div className="flex justify-center items-center">
//                     <p className="flex justify-end text-[13px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">Codes / 1ere facturation:</p>
//                     <div className="flex flex-row items-center gap-2">
//                       {renderStatusIcon(demande.edf_code_statut)}
//                       <p className="flex justify-end text-[12px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">{demande.edf_code}</p>
//                     </div>
//                   </div>
//                   <div className="hidden sm:hidden md:hidden lg:block xl:block 2xl:block"></div>
//                   <div className="">
//                     <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_codes_text}</p>
//                   </div>
//                 </div>

//                 <div className="flex gap-2 items-center justify-center pl-11">
//                   {renderStatuPIcon(demande.edf_piece_statut)}
//                   <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
//                   <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_piece}</p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}


//         {/* Modal de téléchargement */}
//         <UploadModal
//           isOpen={isModalOpen}
//           onClose={() => setIsModalOpen(false)}
//           onSave={handleSaveFiles}
//         />
//       </div>
//       <div className="mt-16">
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default Dashboard;
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import valide from '../img/ico-valid.png';
import non from '../img/ico-non.png';
import vorange from '../img/ico-Vorange.png';
import iconSearch from '../img/ico-loupe.png';
import icon from '../img/icone-debuttexte.png';
import StyledButton from '../components/styleButton';
import UploadModal from '../components/UploadModal';
import FilesModal from '../components/FilesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
  const [demandes, setDemandes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false); 
  const [selectedField, setSelectedField] = useState(null); 
  const [selectedDemandeId, setSelectedDemandeId] = useState(null); 
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState({});
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [filteredDemandes, setFilteredDemandes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const goToDemandeDetails = (demandeId) => {
    navigate(`/demande-details/${demandeId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await fetch('http://localhost:8000/api/user-demandes', {
          const response = await fetch('https://www.lampenergie.fr/admin/public/api/user-demandes', {

          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setDemandes(data);
        setFilteredDemandes(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token]);

  const handleOpenModal = (field, demandeId) => {
    setSelectedField(field);
    setSelectedDemandeId(demandeId);
    setIsModalOpen(true);
  };

  const handleViewFiles = async (field, demandeId) => {
    try {
      // const response = await fetch(`http://localhost:8000/api/files/${demandeId}/${field}`, {
        const response = await fetch(`https://www.lampenergie.fr/admin/public/api/files/${demandeId}/${field}`, {

        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch files: ${response.statusText}`);
      }

      const files = await response.json();
      const formattedFiles = Array.isArray(files)
        ? files.map(file => ({
            name: file.split('/').pop(),
            url: file,
          }))
        : [];

      setSelectedFiles(formattedFiles);
      setIsFilesModalOpen(true);
    } catch (error) {
      console.error('Error fetching files:', error);
      alert('An error occurred while fetching the files.');
    }
  };

  const handleSaveFiles = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files[]', files[i]);
    }
    formData.append('field', selectedField);
    formData.append('id', selectedDemandeId);

    try {
      // const response = await fetch('http://localhost:8000/api/upload-files', {
        const response = await fetch('https://www.lampenergie.fr/admin/public/api/upload-files', {

        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.ok) {
        setUpdatedStatus((prevState) => ({
          ...prevState,
          [`${selectedDemandeId}-${selectedField}`]: true,
        }));
        setIsModalOpen(false);
      } else {
        const errorData = await response.json();
        console.error('Erreur lors de la sauvegarde des fichiers:', errorData);
      }
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case 'traiter':
        return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
      case 'en cours':
        return <img src={vorange} alt="En cours" className="w-6 h-6 inline-block" />;
      case 'non traite':
        return <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />;
      default:
        return null;
    }
  };

  const renderStatuPIcon = (status) => {
    switch (status) {
      case 'traiter':
        return <img src={valide} alt="Valide" className="w-6 h-6 inline-block" />;
      case 'en cours':
        return <img src={vorange} alt="En cours" className="w-6 h-6 inline-block" />;
      case 'non traite':
        return <img src={non} alt="Non traité" className="w-6 h-6 inline-block" />;
      default:
        return null;
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = demandes.filter((demande) =>
      demande.nom_projet && demande.nom_projet.toLowerCase().includes(value)
    );
    setFilteredDemandes(filtered);
  };

  const renderStatuIcon = (value, field, demandeId) => {
    const isUpdated = updatedStatus[`${demandeId}-${field}`];
    const shouldShowValidIcon = isUpdated || (value && value !== 'null' && value !== '' && JSON.stringify(value) !== '[]' && value !== '[[]]');
  
    return (
      <>
        <img src={shouldShowValidIcon ? valide : non} alt={shouldShowValidIcon ? 'Valide' : 'Non traité'} className="w-6 h-6 inline-block" />
        <button
          className="text-red-500 font-bold underline text-[13px]"
          onClick={() => handleOpenModal(field, demandeId)}
        >
          Télécharger
        </button>
        {shouldShowValidIcon && (
          <a onClick={() => handleViewFiles(field, demandeId)} target="_blank" rel="noopener noreferrer" className="ml-2 text-[#006462] ">
            <FontAwesomeIcon icon={faEye} size="lg" />
          </a>
        )}
      </>
    );
  };

  return (
    <div>
      <Header />
      <div className="w-full h-full mx-auto px-4 md:px-4 lg:px-16 xl:px-20 2xl:px-20">
        <div className="flex flex-col sm:flex-col md:justify-between lg:justify-between xl:justify-between md:flex-row lg:flex-row xl:flex-row 2xl:flex-row lg:items-center mb-4">
          <div>
            <ul className="mt-2 ml-4">
              <li className="mb-1">
                <img src={icon} alt="icon" className="inline-block w-6 h-6 -ml-2" />
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-2" onClick={() => navigate('/mon-compte')}>
                  Dossiers en cours
                </a>
              </li>
              <li className="mb-1">
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={() => navigate('/archives')}>
                  Dossiers archivés
                </a>
              </li>
              <li className="mb-1">
                <a className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={() => navigate('/mes-informations')}>
                  Mes informations
                </a>
              </li>
              <li className="mb-1">
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={() => {
                  localStorage.removeItem('token');
                  navigate('/installer-form-login');
                }}>
                  Déconnexion
                </a>
              </li>
            </ul>
          </div>
          <StyledButton type="button" onClick={() => navigate('/creation-demande')}
            >
            NOUVELLE DEMANDE
          </StyledButton>
        </div>

        {/* Barre de recherche */}
        <div className="mb-4">
          <div className="flex items-center p-2 border rounded-lg 2xl:w-[34%] xl:w-[34%] lg:w-[34%] md:w-[34%]">
            <input
              type="text"
              placeholder="RECHERCHE DE DOSSIERS"
              className="flex-grow p-2 focus:outline-none"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <img src={iconSearch} alt="Search" className="w-6 h-6 ml-2" />
          </div>
        </div>


        {filteredDemandes.map((demande, index) => (
          <div key={index} className="w-[100%] mb-14">
            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
              {/* Gestion des documents */}
              <div className="bg-[#d9d8d8]">
                <div className="p-3 bg-[#c3dddc]">
                  {/* <h1 className="text-xl font-normal text-black flex justify-start">{demande.nom_projet || demande.nom}</h1> */}
                  <h1 className="text-xl font-normal text-black flex justify-start">{demande.nom_projet}</h1>

                </div>
                <div className="bg-[#d9d8d8] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-8 p-2 text-[#1c7573] underline">Gestion des documents:</h3>

                  {/* Fiche informations */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Fiche informations</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.fiche_info, 'fiche_info', demande.id)}
                      </div>
                    </div>
                  </div>

                  {/* Mandat */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px]">Mandat</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.mandat_special, 'mandat_special', demande.id)}
                      </div>
                    </div>
                  </div>

                  {/* Dessins, schéma, Photo */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px]">Dessins, schéma, Photo</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.dessins_schema_photo, 'dessins_schema_photo', demande.id)}
                      </div>
                    </div>
                  </div>

                  {/* Photos, autres docs */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px]">Photos, autres docs</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.photos_autres_docs, 'photos_autres_docs', demande.id)}
                      </div>
                    </div>
                  </div>

                  {/* Propriete */}
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/2">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Propriete</p>
                    </div>
                    <div className="w-1/2">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatuIcon(demande.propriete, 'propriete', demande.id)}
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              {/* Voir les Informations */}
              <div className="bg-[#eeebeb]">
              <a href=""  onClick={() => goToDemandeDetails(demande.id)}>
  <div className="p-3 bg-[#1c7573]">
    <h1 className="text-xl font-normal text-center text-[#ffdf76]">
      Voir les Informations
    </h1>
  </div>
</a>
                <div className="bg-[#d9d8d8] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-3 p-2 text-[#1c7573]">
                    DP Urbanisme<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/4">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date dépôt:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.dp_date_depot_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_date_depot}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_date_depot_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2 -ml-8">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Délai :</p>
                    </div>
                    <div className="w-[67%]">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.dp_delai_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_delai}</p>
                      </div>
                    </div>
                  </div>
                  {/* dp_piece */}
                  {demande.dp_piece && (
                    <div className="flex gap-2 items-center justify-start pl-11">
                      {renderStatuPIcon(demande.dp_piece_statut)}
                      <div className="ml-1 text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) DP :</div>
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.dp_piece}</p>
                    </div>
                  )}
                </div>

                {/* Section ENEDIS */}
                <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] lg:font-bold font-bold mb-3 p-2 text-[#1c7573]">
                    ENEDIS ou Régle<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date MES:</p>
                    </div>
                    <div className="w-[67%]">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.enedis_date_mes_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_mes}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_mes_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date PCR:</p>
                    </div>
                    <div className="w-[67%]">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.enedis_pcr_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_pcr}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_date_pcr_text}</p>
                    </div>
                  </div>
                  {/* enedis piece  */}
                  {demande.enedis_piece && (
                    <div className="flex gap-2 items-center justify-start pl-11">
                      {renderStatuPIcon(demande.enedis_piece_statut)}
                      <div className="ml-1 text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) ENEDIS :</div>
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.enedis_piece}</p>
                    </div>
                  )}
                </div>
              </div>

              {/* Section Dossier traité */}
              <div className="bg-[#eeebeb]">
                <div className="p-3 bg-[#c3dddc]">
                  <h1 className="text-xl font-normal text-[#1c7573] flex justify-end pr-10">Dossier traité à : <span className="font-bold">{demande.dossier_traite}</span></h1>
                </div>
                <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] font-bold mb-3 p-2 text-[#1c7573]">
                    CONSUEL<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date complétude:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.consuel_date_completude_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_completude}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_completude_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Date Visa:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.consuel_date_visa_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_visa}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_date_visa_text}</p>
                    </div>
                  </div>
                  {/*  CONSUEL piece */}
                  {demande.consuel_piece && (
                    <div className="flex gap-2 items-center justify-start pl-11">
                      {renderStatuPIcon(demande.consuel_piece_statut)}
                      <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.consuel_piece}</p>
                    </div>
                  )}
                </div>
                <div className="bg-[#d9d8d8] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
                  <h3 className="text-lg lg:text-[1rem] font-bold mb-3 p-2 text-[#1c7573]">
                    DOE (Option si PRO)<span className="text-black font-normal">- Avancement :</span>
                  </h3>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Rapport:
                      </p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.doe_rapport_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_rapport}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_rapport_text}</p>
                    </div>
                  </div>
                  <div className="flex flex-row mb-2 w-[100%] gap-2">
                    <div className="w-1/3">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Envoyé/encours:</p>
                    </div>
                    <div className="">
                      <div className="flex flex-row items-center gap-2">
                        {renderStatusIcon(demande.doe_envoye_statut)}
                        <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_envoye}</p>
                      </div>
                    </div>
                    <div className="">
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_envoi_text}</p>
                    </div>
                  </div>
                  {/* Affichage pour DOE si la pièce n'est pas vide */}
                  {demande.doe_piece && (
                    <div className="flex gap-2 items-center justify-start pl-11">
                      {renderStatuPIcon(demande.doe_piece_statut)}
                      <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
                      <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.doe_piece}</p>
                    </div>
                  )}
                </div>

              </div>
            </div>




            {/* EDF OA OPTION */}
            <div className="bg-[#eeebeb] p-0 sm:p-0 md:p-0 lg:p-1 xl:p-4 2xl:p-4">
              <h3 className="text-md font-bold mb-3 p-2 text-[#1c7573] lg:text-[1rem]">EDF OA OPTION<span className="text-black font-normal">- Avancement :</span></h3>
              <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-2">
                <div className="flex flex-row justify-center sm:justify-center md:gap-12 lg:gap-12 xl:gap-2 2xl:gap-2">
                  <div className="flex justify-center items-center">
                    <p className="flex justify-end text-[13px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[14px] 2xl:text-[15px]">Date création du portail:</p>
                    <div className="flex flex-row items-center gap-2">
                      {renderStatusIcon(demande.edf_date_statut)}
                      <p className="flex justify-end text-[12px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">{demande.edf_date}:</p>
                    </div>
                  </div>
                  <div className="hidden sm:hidden md:hidden lg:block xl:block 2xl:block"></div>
                  <div className="">
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_date_creation_text}</p>
                  </div>
                </div>

                <div className="flex flex-row justify-center sm:justify-center md:gap-12 lg:gap-12 xl:gap-14 2xl:gap-2">
                  <div className="flex justify-center items-center">
                    <p className="flex justify-end text-[13px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">Codes / 1ere facturation:</p>
                    <div className="flex flex-row items-center gap-2">
                      {renderStatusIcon(demande.edf_code_statut)}
                      <p className="flex justify-end text-[12px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[15px] 2xl:text-[16px]">{demande.edf_code}</p>
                    </div>
                  </div>
                  <div className="hidden sm:hidden md:hidden lg:block xl:block 2xl:block"></div>
                  <div className="">
                    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_codes_text}</p>
                  </div>
                </div>

              {/*  EDF pièce */}
{demande.edf_piece && (
  <div className="flex gap-2 items-center justify-center pl-11">
    {renderStatuPIcon(demande.edf_piece_statut)}
    <div className="ml-1 text-[14px] sm:text-[15px] md:text-[16px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">Pièce(s) Manquante(s) :</div>
    <p className="flex justify-end text-[13px] sm:text-[11px] md:text-[11px] lg:text-[11px] xl:text-[16px] 2xl:text-[16px]">{demande.edf_piece}</p>
  </div>
)}

              </div>
            </div>
          </div>
        ))}

        {/* Modal de téléchargement */}
        <UploadModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveFiles}
        />

        {/* Modal pour voir les fichiers */}
        <FilesModal
          isOpen={isFilesModalOpen}
          onClose={() => setIsFilesModalOpen(false)}
          files={selectedFiles}
        />

      </div>
      <div className="mt-16">
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
