import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import valide from '../img/ico-valid.png';
import non from '../img/ico-non.png';
import vorange from '../img/ico-Vorange.png';
import StyledButton from '../components/styleButton';
import icon from '../img/icone-debuttexte.png';
import iconSearch from '../img/ico-loupe.png';

const Archive = () => {
  const navigate = useNavigate();
  const [demandes, setDemandes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDemandes, setFilteredDemandes] = useState([]);

  useEffect(() => {
    const fetchDemandes = async () => {
      try {
        const token = localStorage.getItem('token');
        // const response = await fetch('http://localhost:8000/api/demandes/installateur', {
          const response = await fetch('https://www.lampenergie.fr/admin/public/api/demandes/installateur', {

          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors du fetch des données');
        }

        const data = await response.json();
        setDemandes(data);
        setFilteredDemandes(data);
      } catch (error) {
        console.error('Erreur lors du fetch des demandes :', error);
      }
    };

    fetchDemandes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/mes-informations');
  };

  const handleArchives = (e) => {
    e.preventDefault();
    navigate('/archives');
  };

  const handleCompte = (e) => {
    e.preventDefault();
    navigate('/mon-compte');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/installer-form-login');
  };

  const goToDemandeDetails = (demandeId) => {
    navigate(`/demande-details/${demandeId}`);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = demandes.filter((demande) =>
      demande.nom_projet && demande.nom_projet.toLowerCase().includes(value)
    );
    setFilteredDemandes(filtered);
  };

  const determineGroupStatus = (delai_statut, date_depot_statut, piece_statut) => {
    if (delai_statut === 'traiter' && date_depot_statut === 'traiter' && piece_statut === 'traiter') {
      return 'traiter';
    } else if (delai_statut === 'en cours' && date_depot_statut === 'en cours' && piece_statut === 'en cours') {
      return 'en cours';
    } else if (delai_statut === 'non traite' || date_depot_statut === 'non traite' || piece_statut === 'non traite') {
      return 'non traite';
    } else {
      return 'en cours';
    }
  };

  const renderStatusIcon = (statut) => {
    if (statut === 'traiter') {
      return <img src={valide} alt="Valide" title="Valider" className="w-6 h-6 inline-block" />;
    } else if (statut === 'en cours') {
      return <img src={vorange} alt="En cours" title="En cours" className="w-6 h-6 inline-block" />;
    } else if (statut === 'non traite') {
      return <img src={non} alt="Non validé" title="Non validé" className="w-6 h-6 inline-block" />;
    } else {
      return <img src={vorange} alt="En cours" title="En cours" className="w-6 h-6 inline-block" />;
    }
  };

  const renderMesIcon = (statutDP, statutEnedis, statutConsuel) => {
    if (statutDP === 'traiter' && statutEnedis === 'traiter' && statutConsuel === 'traiter') {
      return <img src={valide} alt="MES Valide" title="MES Valide" className="w-6 h-6 inline-block" />;
    } else if (statutDP === 'en cours' && statutEnedis === 'en cours' && statutConsuel === 'en cours') {
      return <img src={vorange} alt="MES en cours" title="MES en cours" className="w-6 h-6 inline-block" />;
    } else if (statutDP === 'non traite' && statutEnedis === 'non traite' && statutConsuel === 'non traite') {
      return <img src={non} alt="MES Non validé" title="MES Non validé" className="w-6 h-6 inline-block" />;
    } else {
      return <img src={vorange} alt="MES en cours" title="MES en cours" className="w-6 h-6 inline-block" />;
    }
  };

  return (
    <div>
      <Header />
      <div className="w-full h-full mx-auto px-4 md:px-4 lg:px-16 xl:px-20 2xl:px-20">
        <div className="flex flex-col sm:flex-col md:justify-between lg:justify-between xl:justify-between md:flex-row lg:flex-row xl:flex-row 2xl:flex-row lg:items-center mb-4">
          <div>
            <ul className="mt-2 ml-4">
              <li className="mb-1">
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleCompte}>
                  Dossiers en cours
                </a>
              </li>
              <li className="mb-1">
                <img src={icon} alt="icon" className="inline-block w-6 h-6 -ml-2" />
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-2" onClick={handleArchives}>
                  Dossiers archivés
                </a>
              </li>
              <li className="mb-1">
                <a className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleSubmit}>
                  Mes informations
                </a>
              </li>
              <li className="mb-1">
                <a href="#" className="text-[#616565] text-xl font-normal hover:underline ml-6" onClick={handleLogout}>
                  Déconnexion
                </a>
              </li>
            </ul>
          </div>
          <StyledButton type="button" onClick={() => navigate('/creation-demande')}>
            NOUVELLE DEMANDE
          </StyledButton>
        </div>

        {/* Barre de recherche */}
        <div className="mb-4">
          <div className="flex items-center p-2 border rounded-lg 2xl:w-[34%] xl:w-[34%] lg:w-[34%] md:w-[34%]">
            <input
              type="text"
              placeholder="RECHERCHE DE DOSSIERS"
              className="flex-grow p-2 focus:outline-none"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <img src={iconSearch} alt="Search" className="w-6 h-6 ml-2" />
          </div>
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-lg uppercase bg-[#c3dddc]">
              <tr>
                <th scope="col" className="px-6 py-3 text-black font-semibold">Nom Du Chantier/Client</th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">DP</th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">RACCORDEMENT (ENEDIS ou régie)</th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">CONSUEL</th>
                <th scope="col" className="px-6 py-3 text-black font-semibold">MES (MISE EN SERVICE)</th>
              </tr>
            </thead>
            <tbody>
              {filteredDemandes.map((demande, index) => {
                const statutDP = determineGroupStatus(demande.dp_delai_statut, demande.dp_date_depot_statut, demande.dp_piece_statut);
                const statutEnedis = determineGroupStatus(demande.enedis_date_mes_statut, demande.enedis_pcr_statut, demande.enedis_piece_statut);
                const statutConsuel = determineGroupStatus(demande.consuel_date_completude_statut, demande.consuel_date_visa_statut, demande.consuel_piece_statut);

                return (
                  <tr key={index} className={`border-b ${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}`}>
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap cursor-pointer hover:underline"
                      onClick={() => goToDemandeDetails(demande.id)} // Navigate to details on click
                    >
                      {/* {demande.nom_projet || demande.nom} */}
                      {demande.nom_projet}

                    </th>
                    <td className="px-6 py-4">{renderStatusIcon(statutDP)}</td>
                    <td className="px-6 py-4">{renderStatusIcon(statutEnedis)}</td>
                    <td className="px-6 py-4">{renderStatusIcon(statutConsuel)}</td>
                    <td className="px-6 py-4">{renderMesIcon(statutDP, statutEnedis, statutConsuel)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-16">
        <Footer />
      </div>
    </div>
  );
};

export default Archive;
